import React, { useMemo } from "react";
import { Drawer, DrawerContent, DrawerTrigger } from "../common/Drawer";
import StudentAssessmentResultsDrawer from "../StudentAssessmentResultsDrawer";
import {
  AssessmentStatusPill,
  AssessmentStatusIcon,
  getAssessmentStatus,
} from "./AssessmentStatus";
import { timeToDateWithTime } from "../../utils";
import { ChevronRightIcon } from "@heroicons/react/16/solid";

export default function StudentAssessmentItem(props) {
  const status = useMemo(() => getAssessmentStatus(props.meta), [props.meta]);

  return (
    <Drawer>
      <DrawerTrigger>
        <button
          className="py-4 px-4 sm:px-5 flex items-center justify-between w-full group hover:bg-gray-50 group-last:rounded-b-lg"
          data-testid={`student-assessment-results-item-${props.id}`}
          type="button"
        >
          <div className="flex items-center space-x-2">
            <AssessmentStatusIcon status={status} />
            <h5 className="text-gray-600 font-semibold group-hover:underline">
              {props.meta.title}
            </h5>
            <span className="text-xs text-gray-500">
              {timeToDateWithTime(props.meta.submittedAt)}
            </span>
          </div>
          <div className="flex items-center space-x-2">
            <AssessmentStatusPill
              status={status}
              correctCount={props.meta.correctCount}
              totalCount={props.meta.totalCount}
            />
            <ChevronRightIcon className="text-gray-600 w-5 h-5" />
          </div>
        </button>
      </DrawerTrigger>

      <DrawerContent data-testid={`student-assessment-results-drawer-${props.id}`}>
        <StudentAssessmentResultsDrawer {...props} status={status} />
      </DrawerContent>
    </Drawer>
  );
}
