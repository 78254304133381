import React from "react";

import StickyBanner from "../DurationTimer/StickyBanner";
import DurationTimer from "../DurationTimer/DurationTimer";
import FeatureToggle from "../common/FeatureToggle";
import { RailsPathsProvider } from "../../contexts/RailsPathsContext";
import useCountdownTimer from "../../hooks/useCountdownTimer";
import { duration } from "utils/cardTitle";
import { HeartIcon } from "@heroicons/react/24/solid";
import HeartBook from "assets/images/heart-book.svg";
import InfoTooltip from "../common/InfoTooltip";

const StudentPlaceholderLessonCard = ({
  durationSeconds,
  timerSecondsRemaining,
  displayTimer,
  showStickyTimer,
  paths,
}) => {
  const durationTimer = useCountdownTimer(timerSecondsRemaining * 1000);

  return (
    <RailsPathsProvider paths={paths}>
      <div
        className="bg-brand-100 rounded-lg border border-l-8 border-zinc-200 flex flex-col px-4 sm:px-10 pt-6 lg:pt-10 pb-4 lg:pb-10 sm:pb-6 space-y-3 sm:space-y-4"
        data-testid="greeting-card"
      >
        {showStickyTimer && displayTimer && (
          <StickyBanner key="timer-banner">
            <div className="bg-white border-b border-zinc-200 flex items-center p-2 -mx-4 sm:-ml-10 md:-ml-[6.5rem] sm:-mr-10 justify-end">
              <div className="basis-[350px] flex justify-end">
                <DurationTimer className="flex-row-reverse grow" durationTimer={durationTimer} />
              </div>
            </div>
          </StickyBanner>
        )}
        <div className="flex items-center space-x-3 md:space-x-6 mt-0">
          <HeartIcon className="text-zinc-500 w-10 h-10" />
          <div className="flex items-center flex-wrap">
            <h4 className="font-semibold text-xl/none mr-2 text-gray-700">Greeting</h4>
            <FeatureToggle featureName="suggested_card_duration">
              <FeatureToggle.On>
                <p className="text-gray-600 text-sm mr-1">{duration(durationSeconds)}</p>
              </FeatureToggle.On>
            </FeatureToggle>
            <InfoTooltip>
              <h5 className="mb-1.5" key="title-1">
                <strong>Greeting Overview</strong>
              </h5>
              <p className="mb-3" key="paragraph-1">
                Sets a positive tone, maintains the relationship you&#39;ve built, shows respect and
                maintains a predictable routine.
              </p>
              <h5 className="mb-1.5" key="title-2">
                <strong>Pro-tips</strong>
              </h5>
              <ul>
                <li className="mb-1.5" key="list-item-1">
                  <strong>Tutor Preparedness</strong> - Make sure materials are loaded into GSB
                  before starting and every time a student passes their PM.
                </li>
                <li className="mb-1.5" key="list-item-2">
                  <strong>Kid Energy</strong> - If a student comes in ready to share, give space for
                  ~30 seconds, then use an &quot;If... Then...&quot; statement, like &quot;if we
                  have time at the end, then you can finish that story,&quot; to get the lesson
                  started.
                </li>
                <li className="mb-1.5" key="list-item-3">
                  <strong>Use countdowns</strong> - Use short 3 second countdowns as needed for
                  engagement actions like choosing a color for the day
                </li>
              </ul>
            </InfoTooltip>
          </div>
          {displayTimer && (
            <div className="flex items-center justify-end grow">
              <DurationTimer className="flex-row-reverse" durationTimer={durationTimer} />
            </div>
          )}
        </div>
        <div className="md:pl-16">
          <div className="flex flex-col xl:flex-row-reverse justify-between space-y-6 xl:space-y-0">
            <div className="grow-0 shrink xl:basis-[350px] xl:pl-6 border-zinc-200 sticky">
              <div className="xl:px-5 pt-6 xl:pb-8 flex justify-center">
                <HeartBook />
              </div>
            </div>
            <div className="shrink grow basis-auto flex flex-col flex-1 xl:pr-8 max-w-[80rem] relative">
              <div className="flex flex-col">
                <p className="mb-6 bg-brand-50 p-10 rounded-lg" key="paragraph-1">
                  <strong> Bring the joy factor, keep it short! </strong> <br />
                  <i>
                    <strong> Script: </strong> &#34;Hi (student name)! I&#39;m so excited to read
                    with you today! Ready? Let&#39;s go!&#34;{" "}
                  </i>
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RailsPathsProvider>
  );
};
export default StudentPlaceholderLessonCard;
